import {
	Button, Grid,
	makeStyles
} from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import AccessMenu from "src/components/AccessMenu";
import Logo from 'src/components/Logo';
import ResponsiveDialog from 'src/components/ResponsiveDialog';
import useSocket from "src/hooks/useSocket";
import { disconnect } from "src/slices/socket";
import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';
import { SocketDataInterface } from "src/types/socket";
import { resetStation } from 'src/utils/common';
import AccessMenuCodeForm from "src/views/forms/AccessMenuCodeForm";


const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	action: {
		'& + &': {
			marginLeft: theme.spacing(1)
		}
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
	},
	bottomBarWrapper: {
		width: '100%',
		background: 'white',
		padding: 10,
	}
}));

const ColorButton = styled(Button)(({ theme }) => ({
	color: theme.palette.getContrastText(purple[500]),
	width: "200px",
	margin: theme.spacing(3),
	fontSize: theme.typography.pxToRem(20),
	backgroundColor: purple[500],
	'&:hover': {
		backgroundColor: purple[700],
	},
}));

const BottomBar: FC = ({ ...rest }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const { isConnected } = useSelector((state) => state.socket);
	const [errorStatus, setErrorStatus] = useState<boolean>(false);
	const [resetEnable, setResetEnable] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { testEpc, disconnectSocket } = useSocket();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { socketData }: SocketDataInterface = useSelector((state) => state.socket);

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpen = () => {
		const hasMenuAccess: string = localStorage.getItem('hasMenuAccess');
		if (!!hasMenuAccess) {
			setOpenMenu(!openMenu);
		} else {
			setOpen(!open);
		}

	};

	const handleCloseDialog = (type: string) => {
		/*if (type === 'itemCount') {
			setOpenEpc(!openEpc);
		}*/
		setOpenMenu(false);
		setErrorStatus(true);
	};

	const handleFormSubmit = (formValues) => {
		if (formValues && formValues.epcNumber) {
			testEpc({ epc: formValues.epcNumber });
			setOpenMenu(false);
		}
		if (formValues && formValues.passCode) {
			if (process.env.REACT_APP_PASSCODE === formValues.passCode) {
				setOpen(false);
				setOpenMenu(true);
				setMenuAccessCode("true");
			} else {
				enqueueSnackbar(`Invalid passcode`, {
					variant: 'error',
					autoHideDuration: 3000
				});

			}
		}
	};


	const setMenuAccessCode = (hasMenuAccess: string | null): void => {
		if (hasMenuAccess) {
			localStorage.setItem('hasMenuAccess', hasMenuAccess);
		} else {
			localStorage.removeItem('hasMenuAccess');
		}
	};

	const handleDisconnect = () => {
		disconnectSocket();
		dispatch(disconnect());
		handleClose();
		localStorage.removeItem('hasMenuAccess');

	};

	const handleIsReset = (isReset: boolean) => {
		setResetEnable(isReset);
	};
	const handleReset = async () => {
		const status = await resetStation(socketData);

		if (status === 200) {
			history.push('/app/attract');
			enqueueSnackbar(`Reset Station Successfully.`, {
				variant: 'success',
				autoHideDuration: 3000
			});
		} else {
			enqueueSnackbar(`Something went wrong.`, {
				variant: 'error',
				autoHideDuration: 3000
			});

		}


	};
	return (
		<div className={classes.bottomBarWrapper}>
			<ResponsiveDialog
				title={"Enter passcode to access menu"}
				showTitle={true}
				open={open}
				fullWidth={true}
				onlyCloseBtn={true}
				children={
					<AccessMenuCodeForm handleFormSubmit={handleFormSubmit} />
				}
				maxWidth={"xs"}
				handleClose={() => { setOpen(false); }}
			/>
			<ResponsiveDialog
				title={"Menu"}
				showTitle={true}
				open={openMenu}
				fullWidth={true}
				onlyCloseBtn={true}
				children={
					<AccessMenu handleDisconnect={handleDisconnect} handleIsReset={handleIsReset} handleCloseDialog={handleCloseDialog} handleFormSubmit={handleFormSubmit} errorStatus={errorStatus} />
				}
				maxWidth={"xs"}
				handleClose={() => {
					setOpenMenu(false);
					setErrorStatus(true);
				}}
			/>
			<Grid
				container
				className={clsx(classes.root)}
				justify="space-between"
				spacing={3}
				{...rest}>
				<Grid item>
					<Logo onClick={() => { return isConnected ? handleOpen() : {}; }} style={{ width: '55%', cursor: 'pointer' }} />
				</Grid>

				<Grid item>
					<ColorButton
						disabled={!resetEnable}
						variant="contained"
						size="large"
						onClick={() => { handleReset(); }}
					>
						{"RESET"}
					</ColorButton>
				</Grid>

			</Grid>

		</div>
	);
};

export default BottomBar;