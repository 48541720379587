import { api } from 'src/utils/api';
import { StationType } from "src/types/station";
export const appType = "Station Display";
export const groupItems = (items) => {
  const getItems = items.reduce((result, item) => {
    if (item.product && !item.isVoid) {
      result[item.productId] = [...result[item.productId] || [], item];
    } else {
      //Add a fake item for this unknown product
      if (!item.isVoid) {
        let unknownItem = Object.assign({}, item);

        unknownItem.product = {
          name: "Unknown",
          type: "Unknown",
          upc: "Unknown",
          sku: "Unknown",
        };
        result['Unknown'] = [...result[unknownItem.product.upc] || [], unknownItem];
      }
    }
    return result;
  }, {});

  const finalResult = [];

  Object.keys(getItems).forEach((item) => {
    if (getItems[item] && getItems[item][0] && getItems[item][0].product) {
      finalResult.push({
        product: {
          ...getItems[item][0].product,
          quantity: getItems[item].length,
          epcPrefixes: getItems[item]?.map(i => i.product.epcPrefix)
        }
      });
    }
  });
  return finalResult;
};

//remove html tags from a string, leaving only the inner text
export function removeHTML(str) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}
export const resetStation = async (socketData) => {
  const permanentId = socketData?.permanentId;
  const isValidator = socketData?.stationType === StationType.Validator;
  const params = {
    "isValidatorApp": isValidator ? true : false,
    "voidedFrom": appType
  };
  const { status } = await api(`Stations/permanentId/${permanentId}/reset`, "post", params);
  return status;
};
