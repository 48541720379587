/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { FC, useState, createContext, ReactNode, useEffect, useCallback } from "react";
import * as signalR from "@microsoft/signalr";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { useDispatch, useSelector } from "src/store";
import { setSocketData } from "src/slices/socket";
import { SocketDataInterface } from "src/types/socket";
import useAuth from "src/hooks/useAuth";
interface SocketContextInterface {
	activeConnection: any | null;
	socketData: object;
	emitSocketEvent: Function;
	disconnectSocket: Function;
	testEpc: Function;
}

interface SocketProviderInterface {
	children: ReactNode;
}

interface EmitSocketEventInterface {
	station: string;
}


const SocketContext = createContext<SocketContextInterface>({
	activeConnection: {},
	socketData: {},
	emitSocketEvent: () => { },
	disconnectSocket: () => { },
	testEpc: () => { }
});


export const SocketProvider: FC<SocketProviderInterface> = ({ children }) => {
	const [activeConnection, setActiveConnection] = useState(null);
	const [connectedStation, setConnectedStation] = useState(null);
	const { isAuthenticated } = useAuth();

	const dispatch = useDispatch();
	const { socketData }: SocketDataInterface = useSelector((state) => state.socket);

	const subscribeToStationEvents = (activeConnection) => {
		activeConnection.on("StationAsync", (station) => {
			const socketData = {
				socketData: station,
				isConnected: true,
				connecting: false
			};
			dispatch(setSocketData(socketData));
		});
	};

	const registerForStationEvents = async (stationPermanentId) => {
		await activeConnection.send("RegisterAsync", stationPermanentId);
	};

	const emitSocketEvent = async ({ station: stationPermanentId }: EmitSocketEventInterface) => {
		if (stationPermanentId) {
			setConnectedStation(stationPermanentId);
			await activeConnection.start();
			await registerForStationEvents(stationPermanentId);
		}
	};

	const initConnection = useCallback(() => {
		const accessToken: string = localStorage.getItem('accessToken');
		if (accessToken) {
			try {
				const makeConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${process.env.REACT_APP_SOCKET_URL}/stationsHub`, { accessTokenFactory: () => localStorage.getItem('accessToken') })
					.withAutomaticReconnect()
					.build();
				setActiveConnection(makeConnection);
			} catch (e) {
				return false;
			}
		} else {
			return false;
		}
	}, [useIsMountedRef]);

	// reconnect to station if connection lost.
	if (activeConnection?.connection?.connectionState === 'Disconnected' && socketData?.permanentId) {
		emitSocketEvent({ station: socketData?.permanentId });
	}

	const disconnectSocket = () => {
		activeConnection.off("StationAsync");
		activeConnection.stop();
		setActiveConnection(null);
		setConnectedStation(null);
	};

	const testEpc = ({ epc }: { epc: string; }) => {
		activeConnection.send("PostItemAsync", connectedStation, {
			primaryIdentifier: epc
		});
	};

	if (activeConnection) {
		subscribeToStationEvents(activeConnection);

		activeConnection.on("stopAsync", () => {
			// dispatch(clearData()); // We do not need to clear data.
			// history.push('/'); // We do not need to redirect.
		});
	}

	useEffect(() => {
		if (!!activeConnection && socketData?.permanentId) {
			activeConnection.onreconnected(async () => {
				await registerForStationEvents(socketData?.permanentId);
				subscribeToStationEvents(activeConnection);
			});
		}
		return () => {
			if (!!activeConnection) {
				activeConnection.off("onreconnected");
			}
		};
	}, [activeConnection, socketData?.permanentId]);

	useEffect(() => {
		if (!activeConnection) {
			initConnection();
		}
		return () => {
			// on component unmount
			if (activeConnection) {
				disconnectSocket();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeConnection, isAuthenticated]);

	// Removed useEffect and moved the IF condition in to open. As it was previously working. 
	// useEffect(() => {
	// 	if (activeConnection?.connection?.connectionState === 'Disconnected' && socketData?.permanentId) {
	// 			emitSocketEvent({ station: socketData.permanentId });
	// 	}
	// }, [activeConnection?.connection?.connectionState]);

	return (
		<SocketContext.Provider value={
			{
				activeConnection,
				socketData: {},
				emitSocketEvent,
				disconnectSocket,
				testEpc
			}
		}>
			{children}
		</SocketContext.Provider>
	);
};

export default SocketContext;
