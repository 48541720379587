import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'src/store';
import { SocketDataInterface } from 'src/types/socket';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isConnected }: SocketDataInterface = useSelector((state) => state.socket);
  const { isAuthenticated } = useAuth();
  if (isAuthenticated && !isConnected) {
    return <Redirect to="/app/access-code" />;
  } else if (isAuthenticated && isConnected) {
    return <Redirect to="/app/attract" />;
  }
  /*if (isConnected) {
    return <Redirect to="/app/attract" />;
  }*/

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
