import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';

interface ReduxStoreObject {
  initialized: boolean,
  updated: boolean,
  registration: any,
  actionType: string;
};

const initialState: ReduxStoreObject = {
  initialized: false,
  updated: false,
  registration: null,
  actionType: "",
};

const slice = createSlice({
  name: 'serviceWorker',
  initialState,
  reducers: {
    initialize(state: ReduxStoreObject, action: PayloadAction<{ initialized: boolean; }>) {
      const { initialized } = action.payload;
      state.initialized = initialized;
      state.actionType = "SW_INIT";
    },
    update(state: ReduxStoreObject, action: PayloadAction<{ updated: boolean; registration: any; }>) {
      const { updated, registration } = action.payload;
      state.updated = updated;
      state.registration = registration;
      state.actionType = "SW_HAS_AN_UPDATE";
    },
  }
});

export const reducer = slice.reducer;

export const initialize = (data: { initialized: boolean; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.initialize(data));
};

export const update = (data: { updated: boolean; registration: any; }): AppThunk => async (dispatch) => {
  dispatch(slice.actions.update(data));
};

export default slice;