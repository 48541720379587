import React, { FC, useRef, useEffect } from "react";
import { Formik } from 'formik';
import {
    Box,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

interface TestByEPCFormProps {
    handleFormSubmit: Function;
    errorStatus: boolean;
}

const TestByEPCForm: FC<TestByEPCFormProps> = ({ handleFormSubmit, errorStatus }) => {
    const isMountedRef = useIsMountedRef();
    const formRef = useRef(null);

    useEffect(() => {
        if (errorStatus && formRef.current) {
            formRef?.current.resetForm();
        }
    }, [errorStatus]);
    return (<Formik
        innerRef={formRef}
        initialValues={{
            epcNumber: "",
            submit: null
        }}
        validationSchema={Yup.object().shape({
            epcNumber: Yup.string().max(50).required('EPC number is required'),
        })}
        onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting,
            resetForm,
        }) => {
            try {

                await handleFormSubmit(values);


                if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    resetForm({});
                }
            } catch (err) {
                console.error(err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        }}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
        }) => (
            <form
                noValidate
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSubmit();
                        e.preventDefault();
                    }
                }}
            >
                <Box mb={1}>
                    <TextField
                        error={Boolean(touched.epcNumber && errors.epcNumber)}
                        fullWidth
                        autoFocus
                        helperText={touched.epcNumber && errors.epcNumber}
                        label="Type an EPC and press Enter"
                        margin="normal"
                        name="epcNumber"
                        onChange={handleChange}
                        value={values.epcNumber}
                        variant="outlined"
                    />
                </Box>

                {errors.submit && (
                    <Box>
                        <FormHelperText error>
                            {errors.submit}
                        </FormHelperText>
                    </Box>
                )}
            </form>
        )}
    </Formik>);
};

export default TestByEPCForm;
