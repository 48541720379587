import React, { FC } from "react";
import { Formik } from 'formik';
import {
    Box,
    Button,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

interface AccessMenuCodeFormProps {
    handleFormSubmit: Function;
}

const AccessMenuCodeForm: FC<AccessMenuCodeFormProps> = ({ handleFormSubmit }) => {
    const isMountedRef = useIsMountedRef();

    return (<Formik
        initialValues={{
            passCode: "",
            submit: null
        }}
        validationSchema={Yup.object().shape({
            passCode: Yup.string().max(50).required('Passcode is required'),
        })}
        onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
        }) => {
            try {

                await handleFormSubmit(values);

                if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                }
            } catch (err) {
                console.error(err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        }}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
        }) => (
            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <Box mb={1}>
                    <TextField
                        error={Boolean(touched.passCode && errors.passCode)}
                        fullWidth
                        autoFocus
                        helperText={touched.passCode && errors.passCode}
                        label="Enter Passcode"
                        margin="normal"
                        name="passCode"
                        onChange={handleChange}
                        value={values.passCode}
                        variant="outlined"
                    />
                </Box>

                {errors.submit && (
                    <Box>
                        <FormHelperText error>
                            {errors.submit}
                        </FormHelperText>
                    </Box>
                )}
                <Box display="flex" justifyContent="center">
                    <Button
                        disabled={isSubmitting || Boolean(errors.passCode)}
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        Submit
                    </Button>
                </Box>
            </form>
        )}
    </Formik>);
};

export default AccessMenuCodeForm;
