import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from "prop-types";
import type { FC, ReactChild, ReactChildren, ReactNode, ReactElement } from 'react';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Typography } from "@material-ui/core";
interface ResponsiveDialogProps {
  title?: string | ReactElement;
  handleClose?: () => void;
  handleOK?: () => void;
  open?: boolean;
  showTitle?: boolean;
  okBtnText?: string;
  closeBtnText?: string;
  children: ReactChild | ReactChildren;
  actions?: ReactNode;
  onlyOkBtn?: boolean;
  onlyCloseBtn?: boolean;
  style?: object;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  fullWidth?: boolean;
  disableBackdropClickClose?: boolean;
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: "300px", maxHeight: 400, },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResponsiveDialog: FC<ResponsiveDialogProps> = (
  {
    title = "Dialog",
    handleClose = () => { },
    handleOK = () => { },
    open = true,
    children,
    actions = null,
    okBtnText = "OK",
    closeBtnText = "Close",
    onlyOkBtn = false,
    onlyCloseBtn = false,
    style = {},
    maxWidth = false,
    fullWidth = false,
    disableBackdropClickClose = false,
    showTitle = true,
  }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        keepMounted
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={(event, reason) => {
          if (!disableBackdropClickClose) {
            handleClose();
          } else if (disableBackdropClickClose && reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.paper }}
        style={style}
        fullWidth={fullWidth}
        maxWidth={maxWidth as 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false}
      >
        {showTitle ?
          <DialogTitle id="responsive-dialog-title">
            <Typography variant="h4" align="center">{title}</Typography>
          </DialogTitle>
          : ""}
        <PerfectScrollbar>
          <DialogContent>
            <DialogContentText>
              {children}
            </DialogContentText>
          </DialogContent>
        </PerfectScrollbar>
        {actions ? actions : <DialogActions>
          {!onlyOkBtn ? <Button onClick={handleClose} color="primary">
            {closeBtnText}
          </Button> : ""}
          {!onlyCloseBtn ? <Button onClick={handleOK} color="primary">
            {okBtnText}
          </Button> : ""}
        </DialogActions>}
      </Dialog>
    </div >
  );
};

ResponsiveDialog.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.string
};

export default ResponsiveDialog;