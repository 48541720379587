import React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import BottomBar from "src/components/BottomBar";
interface MainLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: 'calc(100% - 100px)',
    overflow: 'hidden',
    width: '100%'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  bottomBar: {
    width: '100%',
    position: 'fixed',
    height: '100px',
    bottom: 0
  }
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>

      <div className={classes.bottomBar}>
        <BottomBar />
      </div>
    </React.Fragment>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
