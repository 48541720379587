import React, { FC, useState } from "react";

import {
  Box, ListItemIcon, ListItemText, MenuItem, MenuList, Switch, FormControlLabel
} from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Details from '@material-ui/icons/Details';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import CollectionsIcon from '@material-ui/icons/Collections';
import TestByEPCForm from "src/views/forms/TestByEPCForm";
import { SocketDataInterface } from "src/types/socket";
import { StationType } from "src/types/station";
import { useHistory } from "react-router";
import { useSelector } from 'src/store';
import { resetStation } from 'src/utils/common';


interface AccessMenuProps {
  handleDisconnect: () => void;
  handleIsReset: (isReset: boolean) => void;
  handleCloseDialog: (type: string) => void;
  handleFormSubmit: Function;
  errorStatus: boolean;
}
const AccessMenu: FC<AccessMenuProps> = ({ handleDisconnect, handleIsReset, handleCloseDialog, handleFormSubmit, errorStatus }) => {

  const history = useHistory();
  const { socketData }: SocketDataInterface = useSelector((state) => state.socket);
  const stationType = socketData?.stationType;
  const isValidator = socketData?.stationType === StationType.Validator;
  const [isReset, setIsReset] = useState<boolean>(false);
  const handleOption = async (type: string) => {
    if (type === 'attract') {
      history.push('/app/attract');
      handleCloseDialog(type);
    } else if (type === 'itemCount') {
      history.push('/app/attract');
      //handleCloseDialog(type);
    } else if (type === 'itemDetail') {
      if (stationType === StationType.Sale) {
        history.push('/app/item-details');
      } else {
        history.push('/app/validator-item-details');
      }
      handleCloseDialog(type);
    } else {

      if (isValidator) {
        const status = await resetStation(socketData);

        if (status === 200) {
          handleCloseDialog(type);
        }
      }
    }
  };

  const handleChange = (event) => {
    setIsReset(event.target.checked);
    handleIsReset(event.target.checked);
  };

  return (
    <Box>
      <MenuList>
        <MenuItem onClick={() => { handleOption('attract'); }}>
          <ListItemIcon >
            <AddShoppingCart />
          </ListItemIcon>
          <ListItemText primary="Attract" />
        </MenuItem>
        <MenuItem onClick={() => { handleOption('itemCount'); }}>
          <ListItemIcon>
            <CollectionsIcon />
          </ListItemIcon>
          <ListItemText primary="Item Count" />

        </MenuItem>
        <MenuItem onClick={() => { handleOption('itemDetail'); }}>
          <ListItemIcon>
            <Details />
          </ListItemIcon>
          <ListItemText primary="Item Details" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary="Reset Station" />
          <FormControlLabel
            control={
              <Switch
                checked={isReset}
                onChange={handleChange}
                value="checked"
                color="secondary"
              />
            }
            labelPlacement="start"
            label={isReset ? "On" : "Off"}
          />
        </MenuItem>
        <MenuItem onClick={handleDisconnect}>
          <ListItemIcon>
            <PowerOffIcon />
          </ListItemIcon >
          <ListItemText primary="Disconnect" />
        </MenuItem>
      </MenuList>
      <TestByEPCForm handleFormSubmit={handleFormSubmit} errorStatus={errorStatus} />
    </Box>

  );
};

export default AccessMenu;
